// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	
	$('.checkerboard-outer-wrapper').each(function() {
		var displaySlides = 1;
        if($(this).hasClass('style-default')){
            displaySlides = 1;
        } else if($(this).hasClass('style-two-images')){
            displaySlides = 2;
        } else if($(this).hasClass('style-three-images')){
            displaySlides = 3;
        }

		var autoScroll = false;
        if($(this).hasClass('auto-scroll')){
            autoScroll = true;
        }

        var scrollSpeed = 300;
        if($(this).hasClass('slow-speed')){
            scrollSpeed = 1000;
        } else if($(this).hasClass('medium-speed')){
            scrollSpeed = 700;
        } else if($(this).hasClass('fast-speed')){
            scrollSpeed = 300;
        }

		$(this).find('.row-checkered-slider .flexslider .slides').each(function() {
			$(this).slick({
				appendArrows: $(this).parent().find('.slick-control-wrapper'),
				appendDots: $(this).parent().find('.slick-control-wrapper'),
				dots: true,
				arrows: false,
				autoplay: autoScroll,
				autoplaySpeed: 5000,
				speed: scrollSpeed,
				slidesToShow: displaySlides,
				responsive: [{
					breakpoint: 768,
					settings: {
						arrows: false,
						slidesToShow: 1,
					}
				}]
			});
		});

		if ($(this).closest('.align-wrap-full').length) {
			$(this).addClass('full-width');
		} else if ($(this).closest('.align-wrap-wide').length) {
			$(this).addClass('default-width');			
		} else {
			$(this).addClass('default-width');
			$(this).addClass('constrained-width-narrow');			
		}

		// remove aria-describedby attr for slides to pass WAVE tool when nav/buttons are hidden
		if( $(this).hasClass('style-default') ){
			$(this).find('.one-slide .flexslider .slides .slick-list .slick-track .slick-slide').each(function() {
				$(this).attr('aria-describedby', '');
			});
		}

		if( $(this).hasClass('style-two-images') ){
			$(this).find('.two-slide .flexslider .slides .slick-list .slick-track .slick-slide').each(function() {
				$(this).attr('aria-describedby', '');
			});
		}

		if( $(this).hasClass('style-three-images') ){
			$(this).find('.three-slide .flexslider .slides .slick-list .slick-track .slick-slide').each(function() {
				$(this).attr('aria-describedby', '');
			});
		}		
	});

}); /* end of as page load scripts */